import { render, staticRenderFns } from "./ConfirmEmailPage.vue?vue&type=template&id=96f50a52&scoped=true&"
import script from "./ConfirmEmailPage.vue?vue&type=script&lang=js&"
export * from "./ConfirmEmailPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96f50a52",
  null
  
)

export default component.exports