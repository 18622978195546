<template>
  <div/>
</template>

<script>
import handleError from 'common/helpers/handleError';
import { notifySuccess } from 'common/helpers/notify';
import { mapActions } from 'vuex';

export default {
  name: 'ConfirmEmailPage',
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  created() {
    this.confirm();
  },
  methods: {
    ...mapActions('app', ['confirmEmail']),
    async confirm() {
      try {
        this.$q.loading.show();
        await this.confirmEmail(this.guid);
        notifySuccess(this.$t('notification.emailSuccessfullyChanged'));
        await this.$router.push({ name: ENUMS.ROUTES.PROFILE.ACCESS_DATA });
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style scoped>

</style>
